import { createMultiStepPlugin } from '@formkit/addons'
import { de, en } from '@formkit/i18n'
import { genesisIcons } from '@formkit/icons'
import { createInput, defineFormKitConfig } from '@formkit/vue'
import NumberInputWithButtons from './components/NumberInputWithButtons.vue'
import { rootClasses } from './formkit.theme'

export default defineFormKitConfig({
  locales: { de, en },
  config: {
    rootClasses,
  },
  icons: {
    ...genesisIcons,
  },
  plugins: [createMultiStepPlugin()],
  inputs: {
    numberInputWithButtons: createInput(NumberInputWithButtons),
  },
})
